<template>
    <div id="page">
        <b-container fluid class="page-header">
            <div class="menu-wrapper">
                <div class="logo" @click="reload()"><SvgLogo /></div>
            </div>
        </b-container>
        <b-container>
            <h1 class="common-page__title mb-4 mt-4 pt-4">
                Manutenzione
            </h1>
            <div class="common-page__content">
            <p>
                Siamo spiacenti, il sito è offline per manutenzione.<br/>
                Ti invitiamo a riprovare più tardi.
            </p>
            </div>
        </b-container>
    </div>
</template>

<script>
import Router from '../../../router'
import SvgLogo from '../../svg/logo'
export default {
    name: 'maintenance',
    components:{
        SvgLogo
    },
    mounted()
    {
        let config = this.appConfig()
        if(Object.keys(config).length)
        {
            Router.push({name: 'homepage'})
        }
        /*
        this.appConfig()
            .then(value => {
                if(Object.keys(value).length)
                {
                    Router.push({name: 'homepage'})
                }
            })
        */
    },
    methods:
    {
        reload()
        {
            window.location.reload()
        }
    }
}
</script>

<style lang="scss">
.maintenance
{
    .logo
    {
        margin:auto;
    }
}
</style>